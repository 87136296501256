.footer {
    background-color: var(--primary);
    color: var(--light);
    padding: 50px 0 20px;
    padding-top: 0;
  }
  
  .footer__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer__content {
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .footer__section {
    text-align: center;
    color:white;
  }
  
  .footer__section h3 {
    color: #FFD700;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .footer__contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .footer__icon {
    color: #FFD700;
    font-size: 18px;
  }
  
  .footer__contact-item a,
  .footer__contact-item span {
    color: var(--light);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer__contact-item a:hover {
    color: #FFD700;
  }
  
  .footer__bottom {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .footer__bottom p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .footer {
      padding: 30px 0 15px;
    }
  
    .footer__content {
      flex-direction: column;
      gap: 30px;
    }
  
    .footer__section {
      width: 100%;
    }
  }