.about {
    padding: 10px 0;
    background-color: #fff;
    border-radius: 25px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .about__title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #06104c;
  }
  
  .about__content {
    max-width: 800px;
    margin: 0 auto 60px;
    text-align: center;
  }
  
  .about__text p {
    margin-bottom: 20px;
    line-height: 1.6;
    color: var(--gray);
    font-size: 1.1rem;
  }
  
  .about__advantages {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    margin-bottom: 0;
    padding: 0 20px;
  }
  
  .advantage-card {
    text-align: center;
    padding: 30px;
    background: #f8f9fa;
    border-radius: 15px;
    transition: transform 0.3s ease;
    box-shadow: 0 0 10px rgb(146 146 146 / 50%);
  }
  
  .advantage-card:hover {
    transform: translateY(-10px);
  }
  
  .advantage-card__icon {
    font-size: 2.5rem;
    color: #FFD700;
    margin-bottom: 20px;
  }
  
  .advantage-card__title {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: var(--dark);
  }
  
  .advantage-card__description {
    color: var(--gray);
    line-height: 1.5;
  }
  
  .about__stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
    text-align: center;
    padding: 40px 20px;
    background: var(--primary);
    border-radius: 10px;
  }
  
  .stat-item h3 {
    font-size: 2.5rem;
    color: #FFD700;
    margin-bottom: 10px;
  }
  
  .stat-item p {
    color: var(--light);
    font-size: 1.1rem;
  }
  
  @media (max-width: 768px) {
    .about {
      padding: 10px;
    }
  
    .about__title {
      font-size: 2rem;
    }
  
    .about__text p {
      font-size: 1rem;
    }
  
    .about__advantages {
      grid-template-columns: 1fr;
    }
  
    .stat-item h3 {
      font-size: 2rem;
    }
  }
  @media (max-width: 500px) {
    .about{
        margin-top: 70px;
    }
    .about__title{
  margin-bottom: 30px;
    }
    .about__content{
        margin-bottom: 30px;
    }
    .about__stats{
        padding: 0;
    }
    .stat-item h3 {
    margin-top: 20px;
    }
  }