.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--primary);
    z-index: 1000;
  }
  
  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding-left: 20px;
    padding-right: 20px;
    color:white;
    background-color: #02061e;
    border-bottom: 1px solid #1f1f1f;
  }
  
  .nav-link:hover {
    color: #FFD700;
    transition: color 0.3s ease;
  }

  /* Или если хотите использовать ID */
.header__menu a[href="#services"]:hover,
.header__menu a[href="#about"]:hover,
.header__menu a[href="#contacts"]:hover {
  color: #FFD700;
  transition: color 0.3s ease;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 3px 0;
  flex-direction: row-reverse;
}

.contact-icon {
  color: #FFD700;
  font-size: 16px;
}

.contact-item a {
  color: var(--light);
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-item a:hover {
  color: #FFD700;
}

/* Для мобильных устройств */
@media (max-width: 768px) {
  .header__phone {
    display: none;
  }
}

  .header__logo {
    font-size: 24px;
    font-weight: 700;
    color: var(--light);
  }
  
  .header__nav {
    display: flex;
    align-items: center;
  }
  
  .header__menu {
    display: flex;
    list-style: none;
    gap: 30px;
  }
  
  .header__menu a {
    color: var(--light);
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;
  }
  
  .header__menu a:hover {
    color: var(--secondary);
  }
  
  .header__phone{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 16px;
  }
  .header__phone a {
    color: var(--light);
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
  }
  
  .header__burger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
  }
  
  .header__burger span {
    display: block;
    width: 25px;
    height: 2px;
    background: var(--light);
    position: relative;
  }
  
  .header__burger span::before,
  .header__burger span::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 2px;
    background: var(--light);
    left: 0;
  }
  
  .header__burger span::before {
    top: -8px;
  }
  
  .header__burger span::after {
    bottom: -8px;
  }
  .header__burger {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 32px; /* увеличил размер */
    cursor: pointer;
    padding: 8px; /* увеличил отступы */
    transition: all 0.3s ease;
    z-index: 1000;
  }
  
  .header__burger svg {
    width: 32px; /* явно задаем ширину */
    height: 32px; /* явно задаем высоту */
  }
  
  .header__burger:hover {
    color: #FFD700;
    transform: scale(1.1);
  }
  .header__burger {
    font-size: 20px; /* еще больше */
  }
  
  .header__burger svg {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 768px) {
    .header__burger {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .header__burger svg {
      transition: transform 0.3s ease;
    }
    
    .header__burger:active svg {
      transform: scale(0.9);
    }
  }

  @media (max-width: 768px) {
    .header__burger {
      display: block;
    }
  
    .header__nav {
      position: absolute;
      top: 80px;
      right: 0;
      width: 50%;
      background: var(--primary);
      padding: 20px 0;
      display: none;
    }
  
    .header__nav.active {
      display: block;
      width: 200px;
      background-color: #02061e;
      border-bottom: 1px solid #1f1f1f;
      border-left: 1px solid #1f1f1f;
      border-bottom-left-radius: 20px;
    }
  
    .header__menu {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 0;
      margin: 0;
    }
  
    .header__phone {
      display: none;
    }
  }