.hero {
  height: 100vh;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 80px;
  color: white;
}

.hero__container {
  height: 100%;
  display: flex;
  position: relative;
}
.carPic {
  width: 80%;
  position: absolute;
  top: 20%;
  right: -30px;
  z-index: 10;
}
.hero__content {
  max-width: 600px;
  color: var(--light);
  z-index: 11;
  margin-top: 50px;
  margin-left: 50px;
}

.hero__content h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero__content p {
  font-size: 20px;
  margin-bottom: 30px;
}

.hero__button {
    padding: 15px 30px;
    background: #ffd700;
    border: 2px solid #ffd700;
    color: black;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease; 
    position: relative; 
    z-index: 100; 
  }
  
  .hero__button:hover,
  .hero__button:active,
  .hero__button:focus { 
    background: transparent;
    color: #ffd700;
    border: 2px solid #ffd700;
    outline: none; 
  }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}
.header__phone_small{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    position: relative;
    margin-top: 30px;
    z-index: 100;
}

.header__phone_small a{
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}
@media (min-width: 700px){
    .header__phone_small{
       display: none;
    }
}
@media (max-width: 420px){
    .header__phone_small a{
        font-size: 14px;
    }
    .header__phone_small{   
        margin-top: 10px;
    }
}
.modal {
  background: white;
  padding: 20px;
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  position: relative;
  animation: slideIn 0.3s ease;
  margin-left: 20px;
  margin-right: 20px;
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 15px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  color: gray;
}

.modal h2 {
  margin-bottom: 15px;
  margin-top: 10px;
  color: #02061e;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #02061e;
}

.form-group input,
.form-group textarea {
  max-width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-group textarea {
  height: 100px;
  resize: none;
}
.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border: 1px solid #ffd700; 
}
.modal__submit {
  width: 100%;
  padding: 12px;
  color: white;
  background-color: #02061e;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 5px;
}

.modal__submit:hover {
  color: black;
  background: #ffd700;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 950px) {
    .carPic {
      width: 95%;
      padding: 0;
    }
  }

  @media (max-width: 810px) {
    .carPic {
        top: 30%;
    }
  }
  @media (max-width: 700px) {
    .carPic {
        top: 40%;
    }
    .hero__content{
        margin-left: 20px;
    }
  }
  @media (max-width: 600px) {
    .carPic {
        top: 48%;    }
  }
  @media (max-width: 550px) {
    .hero__content h1 {
        font-size: 42px;
     }
  }

  @media (max-width: 500px) {
    .hero__content h1 {
        font-size: 40px;
     }
    .hero__content p {
        font-size: 18px;
     }
     .hero__content{
        margin-top: 20px;
        margin-left: 0;
     }
     .carPic{
        width: 120%;
        right: -20%;
        top: 42%;
     }
   
  }

  @media (max-width: 445px) {
  .hero__content h1 {
    font-size: 38px;
  }
  .hero__content p {
    font-size: 16px;
  }
}

@media (max-width: 420px) {
    .hero__content h1 {
      font-size: 32px;
    }
    .hero__content p {
      font-size: 14px;
    }
  }
  @media (max-width: 415px) {
    .carPic{
        top: 35%;
     }
  }
  @media (max-width: 402px) {
    .carPic{
        top: 42%;
     }
  }
  @media (max-width: 399.9px) {
    .carPic{
        top: 35%;
     }
  }
  @media (max-width: 370px) {
    .hero__content h1 {
      font-size: 28px;
    }
    .hero__content p {
      font-size: 12px;
    }
    
  }

@media (max-width: 768px) {
  .modal {
    width: 95%;
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .hero__content h1 {
  }

  .hero__content p {
  }
}
