.services {
    padding: 80px 0;
    background-color: var(--light);
    position: relative;
  }
  
  .services__title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: rgb(255, 215, 0);
  }
  .wheelPic{
    width: 50%;
    position: absolute;
    bottom: -40%;
    right: -20%;

  }
  @media (max-width: 1223px) {
    .wheelPic{
        width: 70%;
        position: absolute;
        bottom: -30%;
        right: -30%;
      }
  }
  .wrenchPic{
    width: 25%;
    position: absolute;
    transform: scaleX(-1);
    top: -5%;
    left: -6%;
    z-index: 30;
  }
  @media (max-width: 1243px) {
    .wrenchPic{
        width: 30%;
        position: absolute;
        transform: scaleX(-1);
        top: -5%;
        z-index: 30;
      }
  }


  @media (max-width: 1100px) {
    .wrenchPic{
        top: 0%;
    }
  }
  @media (max-width: 987px) {
    .wheelPic{
        bottom: -10%;
    }
  }
  @media (max-width: 900px) {
    .wrenchPic{
        top: 5%;
    }
  }
  @media (max-width: 768px) {
    .services {
      padding: 60px 0;
    }
  
    .services__title {
      font-size: 2rem;
      margin-bottom: 30px;
    }
  
    .services__grid {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }

  @media (max-width: 660px) {
    .wrenchPic{
        top: 7%;
        width: 40%;
        left: -10%;
    }
  }

  @media (max-width: 500px) {
    .services {
        padding: 0;
        margin-top: -100px;
    }
    .wrenchPic{
        top: 2%;
        width: 50%;
        left: -10%;
    }

    .service-card{
        padding: 20px;
    }
    .service-card__title{
        margin-top: 0;
    }
    .wheelPic{
        width: 80%;
        bottom: -11%;
  }
  }

  @media (max-width: 450px) {
    .wheelPic{
        bottom: -10%;
    }
  }
  @media (max-width: 430px) {
    .wheelPic{
        width: 84%;
    }
  }
  @media (max-width: 415px) {
    .services{
        margin-top: -200px;
     }
  }
  @media (max-width: 402px) {
    .services{
        margin-top: -100px;
     }
  }
  @media (max-width: 390px) {
    .services{
        margin-top: -250px;
    }
  }
  @media (max-width: 376px) {
    .services{
        margin-top: -150px;
    }
  }
  @media (max-width: 360px) {
    .wheelPic{
        width: 100%;
        right: -40%;
    }
  }

  @media (max-width: 320px) {
    .wheelPic{
        bottom: -7%;
    }
  }


 
  .services__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    padding: 0 20px;
    position: relative;
  }
  
  .service-card {
    background: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgb(36 50 130 / 97%);
    transition: transform 0.3s ease;
    text-align: center;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
  }
  
  .service-card__icon {
    font-size: 3rem;
    color: var(--secondary);
    margin-bottom: 20px;
  }
  
  .service-card__title {
    font-size: 1.5rem;
    color: var(--dark);
    margin-bottom: 15px;
  }
  
  .service-card__description {
    color: var(--gray);
    line-height: 1.6;
  }
  
